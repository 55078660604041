<template>
  <main id="content" role="main">
    <div class="position-absolute top-0 right-0 bottom-0 left-0 bg-img-hero" style="background-image:linear-gradient(to bottom, rgba(19, 24, 54, 0.10), rgba(19, 24, 54, 0.40)),url(./assets/img/cover-desert.jpg);"></div>
    <!-- Form -->
    <div class="d-flex align-items-center position-relative vh-lg-100">
      
      <div class="col-lg-5 col-xl-4 d-none d-lg-flex align-items-center vh-lg-100 px-0">
        
        <div class="w-100 p-5 text-center">
          <a href="/">
            <img src="../../public/assets/img/logo.png" class="img-fluid mb-5" style="max-width:60%" alt="Logo">
          </a>

          <!-- Clients -->
          <div class="position-absolute right-0 bottom-0 left-0 text-center p-5">
            <!--<span class="d-block text-gray-70 mb-3">Our integrations include</span>-->
            <div class="d-flex row justify-content-between text-center">
              <div class="col-4">
                <img class="max-w-7rem mx-auto" src="../../public/assets/img/woosports.png" style="filter: brightness(0) invert(1);" alt="Image Description">
              </div>
              <div class="col-4">
                <img class="max-w-8rem mx-auto" src="../../public/assets/img/strava.png" style="filter: brightness(0) invert(1);" alt="Image Description">
              </div>
              <div class="col-4">
                <img class="max-w-10rem mx-auto" src="../../public/assets/img/instagram.png" style="filter: brightness(0) invert(1);" alt="Image Description">
              </div>
            </div>
          </div>
          <!-- End Clients -->
        </div>
      </div>

      <div class="container">
        <div class="row no-gutters">
          <div class="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-top-2 space-lg-0">
            
            <div class="d-md-none text-center">
              <img src="../../public/assets/img/logo.png" class="img-fluid mb-8" style="max-width:60%" alt="Logo">
            </div>

            
            <a class="btn btn-sm btn-link text-white" href="/">
              <i class="fas fa-angle-left fa-sm mr-1"></i> Go to landing page
            </a>
            <!-- Form -->
            <form @submit.prevent v-if="link==0">
              <!-- Title -->
              <div class="mb-3 mb-md-5">
                <h1 class="h1 text-white">Welcome back</h1>
              </div>
              <!-- End Title -->

              <!-- Form Group -->
              <div class="form-group">
                <input v-model.trim="email" type="email" class="form-control" name="email" id="signinSrEmail" tabindex="1" placeholder="Email address" aria-label="Email address" required
                      data-msg="Please enter a valid email address.">
              </div>
              <!-- End Form Group -->

              <!-- Button -->
              <div class="row align-items-center mb-5">
                <div class="col-sm-6 mb-3 mb-sm-0">
                  <span class="font-size-1 text-white">Don't have an account?</span>
                  <a class="font-size-1 font-weight-bold" href="/"> Sign up</a>
                  <br>
                  <span class="font-size-1 text-white">Forgot your emailadress?</span>
                  <a class="font-size-1 font-weight-bold" href="/contact"> Contact us</a>
                </div>

                <div class="col-sm-6 text-sm-right">
                  <button @click="sendLink" class="btn btn-primary transition-3d-hover">Email login link</button>
                </div>
              </div>
              <!-- End Button -->
            </form>
            <!-- End Form -->
            <!-- CTA Section -->
            <div class="card bg-primary text-white overflow-hidden p-4" v-if="link==1">
              <div class="row justify-content-md-start align-items-md-center text-center text-md-left">
                <div class="col-8 mb-3 mb-md-0">
                  <h3 class="text-white mb-1">Please check your email</h3>
                  <p class="text-white-70 mb-0">Didn't receive an email?<br><a @click="link=0" style="color:inherit;text-decoration:underline">Try again</a> or get in touch</p>
                </div>

                <div class="col-4 text-md-right">
                  <a class="btn btn-light transition-3d-hover" href="/contact">Contact</a>
                </div>
              </div>
            </div>
            <!-- End CTA Section -->
             <!-- Form -->
            <form @submit.prevent v-if="link==2">
              <!-- Title -->
              <div class="mb-3 mb-md-5">
                <h1 class="h1 text-white">Confirm your email adress</h1>
              </div>
              <!-- End Title -->

              <!-- Form Group -->
              <div class="form-group">
                <input v-model.trim="email" type="email" class="form-control" name="email" id="signinSrEmail" tabindex="1" placeholder="Email address" aria-label="Email address" required
                      data-msg="Please enter a valid email address.">

              </div>
              <!-- End Form Group -->
              <button @click="login" class="btn btn-primary transition-3d-hover">Login</button>
            </form>

          </div>
        </div>
      </div>
      
    </div>
    <!-- End Form -->
  </main>
</template>

<script setup>
import * as fb from '../firebase'
import { ref } from 'vue'
import router from '../router'

const email = ref('')
const link = ref(0)
const url = window.location.href

fb.auth.onAuthStateChanged(user => {
    if (user) {
        router.replace(sessionStorage.getItem('redirectPath') || '/feed')
    }
})

const login = () => {
  fb.auth.signInWithEmailLink(email.value, url)
}

const sendLink = () => {
  const actionCodeSettings = {
    url: url,
    handleCodeInApp: true,
  }
  fb.auth.sendSignInLinkToEmail(email.value, actionCodeSettings)
  .then(() => {
    window.localStorage.setItem('emailForSignIn', email.value);
    link.value = 1
  })
  .catch((error) => {
    console.log(error)
  });
}

try {
  if (fb.auth.isSignInWithEmailLink(url)) {
    email.value = window.localStorage.getItem('emailForSignIn')
    if (!email.value) {
      link.value = 2
    }
    else {
      window.localStorage.removeItem('emailForSignIn')
      login()
    }    
  }
} catch (err) {
  console.log(err.message)
}

</script>
